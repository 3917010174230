<template>
  <div id="change-profile-pic">
    <v-toolbar dark color="primary">
      <v-toolbar-title>Change Profile Picture</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-form
        ref="form1"
        v-model="valid1"
        lazy-validation
        v-on:submit.prevent="submitForm"
      >
        <v-card-title>
          <span class="headline"> {{ pageTitle }} </span>
        </v-card-title>
        <v-card-subtitle class="text-h5">
          <!-- <h5>{{ pageDescription }}</h5> -->
        </v-card-subtitle>
        <v-card-text>
          <!-- <small>*indicates required field</small> -->
          <v-container>
            <div class="d-flex justify-content-center mb-3" v-if="SearchFlag">
              <b-spinner
                variant="primary"
                type="grow"
                label="Loading..."
              ></b-spinner>
            </div>
            <v-container>
              <v-row wrap>
                <v-col align="start" cols="12" md="12">
                  <p style="font-size: 14px; font-weight: bold">Notes:</p>
                  <ol style="font-size: 14px; font-weight: bold">
                    <li>Only jpg images are accepted.</li>
                    <li>Upload your image only till head to chest.</li>
                    <li>Kindly upload photos in professional attire.</li>
                    <li>
                      This is the same photo of yours that will reflect in all
                      the places across JCI India software, website and APPs.
                    </li>
                    <li>
                      NHQ has the right to block a photo if it is offensive or
                      if it doesn't meet the instructions above.
                    </li>
                  </ol>
                </v-col>
              </v-row>
            </v-container>
            <v-row wrap>
              <v-col md="8">
                <cropper
                  ref="cropper"
                  :src="MemberImage.ImageUrl"
                  :stencil-props="{
                    handlers: {},
                    movable: false,
                    scalable: false,
                    aspectRatio: 1,
                    previewClass: 'preview',
                  }"
                  :stencil-size="{
                    width: 300,
                    height: 300,
                  }"
                  image-restriction="stencil"
                />
              </v-col>
              <v-col md="4">
                <br />
                <!-- <div class="button-wrapper">
                  <button class="button" @click="$refs.file.click()">
                    <input
                      type="file"
                      ref="file"
                      @change="loadImage($event)"
                      accept="image/*"
                    />
                  </button>
                </div> -->
                <v-file-input
                  :clearable="false"
                  truncate-length="10"
                  accept="image/jpeg"
                  placeholder="Upload Invitation"
                  v-model="UploadImage1"
                  prepend-icon="mdi-camera"
                  @change="addImage(1)"
                >
                  <template v-slot:append-outer>
                    <v-icon color="red" @click="deleteImage(1)">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-file-input>
                <br />
                <div class="actions">
                  <b-button-group vertical class="mr-3 mb-3">
                    <v-btn
                      fab
                      dark
                      small
                      color="indigo"
                      @click.prevent="flip(true, false)"
                    >
                      <v-icon>fa fa-caret-right</v-icon>
                    </v-btn>
                  </b-button-group>

                  <b-button-group vertical class="mr-3 mb-3">
                    <v-btn
                      fab
                      dark
                      small
                      color="indigo"
                      @click.prevent="flip(false, true)"
                    >
                      <v-icon>fa fa-caret-up</v-icon>
                    </v-btn>
                  </b-button-group>

                  <b-button-group vertical class="mr-3 mb-3">
                    <v-btn
                      fab
                      dark
                      small
                      color="indigo"
                      @click.prevent="rotate(90)"
                    >
                      <v-icon>fa fa-redo</v-icon>
                    </v-btn>
                  </b-button-group>

                  <b-button-group vertical class="mr-3 mb-3">
                    <v-btn
                      fab
                      dark
                      small
                      color="indigo"
                      @click.prevent="rotate(-90)"
                    >
                      <v-icon>fa fa-undo</v-icon>
                    </v-btn>
                  </b-button-group>
                </div>
                <v-btn
                  @click.prevent="submitForm"
                  :loading="SubmitFlag"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                >
                  Upload Image
                </v-btn>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" md="12"> </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn
            @click="closePrompt"
            elevation="30"
            shaped
            tile
            small
            color="#a52a2a"
            class="font-size-h6 px-10 mr-3 white--text"
          >
            Close
          </v-btn> -->
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    addRecordPrompt: {
      type: Boolean,
      required: true,
    },
    currentPhoto: {
      type: String,
    },
    pageId: {
      //   required: true,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      LoadingFlag: false,
      SearchFlag: false,
      SubmitFlag: false,

      pageTitle: "",
      pageDescription: "",

      pageContent: "",

      rows: [],

      image: {
        src: "media/images/white-user.png",
        type: null,
      },

      newimage: "",
      selectedImage: "",

      UploadImage1: {},
      MemberImage: { src: null },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    // this.initialize();
  },
  created() {
    this.MemberImage.src = this.currentPhoto;
  },
  methods: {
    initialize() {
      console.log("initialize called");
      var options = this.cropImageOptions;
      console.log("options=" + JSON.stringify(options));
      // this.$refs.cropper.setDefaults(options);
      this.$refs.cropper.setAspectRatio(options.aspectRatio);
      // this.$refs.cropper.setCropBoxData(this.cropBoxData);
      this.$refs.cropper.setDragMode("move");
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.UploadImage1;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.MemberImage.src) {
            URL.revokeObjectURL(this.MemberImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.MemberImage = {
            ImageName: file.name,
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.MemberImage = {
            ImageName: null,
            ImageType: null,
            ImageUrl: null,
            UploadedImage: null,
          };
          this.UploadImage1 = {};
          break;

        default:
          break;
      }
    },
    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => {
        // Do something with blob: upload to a server, download and etc.
      }, this.image.type);
    },
    reset() {
      this.image = {
        src: null,
        type: null,
      };
    },
    loadImage(event) {
      console.log("loadImage is called");
      // Reference to the DOM input element
      const { files } = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. The steps below are designated to determine a file mime type to use it during the
        // getting of a cropped image from the canvas. You can replace it them by the following string,
        // but the type will be derived from the extension and it can lead to an incorrect result:
        //
        // this.image = {
        //    src: blob;
        //    type: files[0].type
        // }

        // Create a new FileReader to read this image binary data
        const reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.image" refers to the image of Vue component
          this.image = {
            // Set the image source (it will look like blob:http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
            src: blob,
            // Determine the image type to preserve it during the extracting the image from canvas:
            type: getMimeType(e.target.result, files[0].type),
          };
        };
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsArrayBuffer(files[0]);
      }
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    flip(x, y) {
      console.log("flip x=" + x + ", y=" + y);
      this.$refs.cropper.flip(x, y);
    },
    rotate(angle) {
      console.log("rotate=" + angle);
      this.$refs.cropper.rotate(angle);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    setImage(e) {
      console.log("setImage called");
      const file = e.target.files[0];
      this.fileExtension = file.name.split(".").pop();
      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
          var options = this.cropImageOptions;
          console.log("options=" + JSON.stringify(options));
          this.$refs.cropper.initialize();
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
    download() {
      if (this.cropImg) {
        var extention = this.fileExtension;
        var href = this.cropImg;
        var link = document.createElement("a");
        link.addEventListener(
          "click",
          function () {
            link.href = href;
            link.download = "cropper-img." + extention;
            // console.log(link.download);
          },
          false
        );
        link.click();
      } else {
        alert("Please, crop image before download it");
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      this.closePrompt();
      //   this.$refs.ProfilePicture.reset();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt", this.newimage);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var pageId = this.pageId;
      console.log("pageId=" + pageId);
      //   this.getMemberDetails();
    },
    submitForm() {
      console.log("submitForm called");
      var message = "";
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        var file = this.UploadImage1;
        var FileType = file.type;
        var ImageName = file.name;
        console.log({ FileType });
        console.log({ ImageName });
        var image = "";
        canvas.toBlob((blob) => {
          image = canvas.toDataURL();
          console.log("image=" + image);
          this.selectedImage = image;
          const formData = new FormData();
          formData.append("ProfilePicture", blob, ImageName);
          this.uploadImage(formData);
          // Perhaps you should add the setting appropriate file format here
          // this.closePrompt();
        }, FileType);
      } else {
        message += "Kindly select image. ";
        this.toast("error", message, true);
      }
    },
    /*
    uploadImage(formData) {
      const token = getCookie("ifragasatt_cookie");
      const url = "https://vem-user.fjardestatsmakten.se/userProfilePic";
      const headers = {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "ifr-jwt-token": token
      };

      axios.post(url, formData, { headers });
    },
    */
    uploadImage(upload) {
      console.log("uploadImage is called");
      /*
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      console.log("coordinates=" + JSON.stringify(coordinates));
      console.log("canvas=" + JSON.stringify(canvas));
      this.coordinates = coordinates;
      this.croppedImage = canvas.toDataURL();
      */
      // var ProfilePicture = this.$refs.input.files[0];
      // var ProfilePicture = this.$refs.cropper.getCroppedCanvas().toDataURL();
      var ProfilePicture = true;
      // console.log("ProfilePicture=" + JSON.stringify(ProfilePicture));

      if (ProfilePicture) {
        this.SubmitFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/profile-picture-update";
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        var selectedImage = this.selectedImage;

        // let upload = new FormData();

        // var ProfilePicture = this.$refs.cropper.getCroppedCanvas().toDataURL();

        upload.append("FileId", 1);

        // if (ProfilePicture) {
        // upload.append("ProfilePicture", ProfilePicture);
        // }
        /*
        var upload = {
          member: member,
        };
        */
        console.log("upload=" + JSON.stringify(upload));

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.toast("success", output);
              thisIns.newimage = selectedImage;
              thisIns.$session.set("ProfilePic", selectedImage);
              thisIns.resetForm();
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "";
        if (ProfilePicture == "") {
          message += "File is not selected";
        }
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#change-profile-pic {
  .cropper {
    height: 300px !important;
    background: #ddd;
  }
}
</style>